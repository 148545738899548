<template>
  <div class="bordered">
    <Title>中医培训</Title>
    <div class="echarts">
      <div class="dashboard">
        <BaseEchart :option="option2" style="width: 100%; height: 100%"></BaseEchart>
        <img src="../../../assets/imgs/dashboard.png" alt="" />
        <div class="circle">
          <span class="txt">{{ dashValue1 }}<span class="suffix">%</span></span>
        </div>
        <div class="title">
          <span>培训通过率</span>
        </div>
      </div>
      <div class="dashboard">
        <BaseEchart :option="option3" style="width: 100%; height: 100%"></BaseEchart>
        <img src="../../../assets/imgs/dashboard.png" alt="" />
        <div class="circle">
          <span class="txt">{{ dashValue2 }}<span class="suffix">%</span></span>
        </div>
        <div class="title">
          <span>培训转化率</span>
        </div>
      </div>
    </div>
    <div style="display: flex; flex-direction: column; align-items: center">
      <div v-for="item in serveData" class="item">
        <span>{{ item.label }}</span>
        <countTo class="column2" :startVal="0" :endVal="item.value" :duration="CountAnimationDuration"></countTo>
      </div>
    </div>
    <BaseEchart :option="option" style="width: 100%; height: 284px"></BaseEchart>
  </div>
</template>
<script>
import Title from '../components/Title.vue';
import '@/assets/icon-font/ABDUCTION2000.css';
import TotalAnalysis from '../components/TotalAnalysis.vue';
import BaseEchart from '@/components/BaseEchart';
import DashboardEchart from './component/Dashboard.vue';
import MockChinaData from '@/utils/MockChinaData';
import { random } from '@/utils';
import { mutBarChart } from '../common';
import { mapState } from 'vuex';
import { randomByLevel } from '@/views/homePage/common';
import { CountAnimationDuration } from '@/views/homePage/common';
import * as echarts from 'echarts';
import countTo from 'vue-count-to';

export default {
  components: { Title, TotalAnalysis, BaseEchart, DashboardEchart, countTo },
  data() {
    return {
      option: undefined,
      option2: undefined,
      option3: undefined,
      CountAnimationDuration,
      analysisNum: 40758,
      serveDataf: [
        {
          label: '培训次数',
          value: 40758,
        },
        {
          label: '就业总数',
          value: 21562,
        },
      ],
      dashValue1: 0,
      dashValue2: 0,
    };
  },
  computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
    valueByLevel() {
      return this.analysisNum / (this.mapLevel + 1);
    },
    serveData() {
      return this.serveDataf.map((item) => {
        return {
          ...item,
          value: item.value / (this.mapLevel + 1),
        };
      });
    },
  },
  methods: {
    init() {
      const dataX = Object.keys(MockChinaData).map((k) => {
        return { name: k, value: random(0, 100) };
      });
      this.option = mutBarChart({
        leArr: ['培训次数', '就业总数'],
        xData: dataX.map((d) => d.name),
        yFirst: dataX.map((d) => d.value * 5),
        ySecond: dataX.map((d) => d.value * 8),
      });
      this.dashValue1 = (this.mapLevel + 1) * random(10, 50);
      this.dashValue2 = (this.mapLevel + 1) * random(60, 90);
      this.option2 = this.genDashChartOption(this.dashValue1)
      this.option3 = this.genDashChartOption(this.dashValue2)
    },

    genDashChartOption(value) {
      let nqColor = [
        [
          value / 100,
          new echarts.graphic.LinearGradient(0, 0, 1, 1, [
            {
              offset: 0,
              color: 'rgba(86, 212, 212,1)',
            },
            {
              offset: 1,
              color: 'rgba(74, 156, 156, 0.7) ',
            },
          ]),
        ],
        [
          1,
          new echarts.graphic.LinearGradient(0, 0, 1, 1, [
            {
              offset: 0,
              color: 'rgba(97, 206, 206, 0.3)',
            },
            {
              offset: 1,
              color: 'rgba(97, 206, 206, 0.3)',
            },
          ]),
        ],
      ];
      return {
        backgroundColor: '#021434',
        tooltip: {
          formatter: '{a} <br/>{b} : {c}%',
        },
        series: [
          {
            name: '最外部进度条',
            type: 'gauge',
            radius: '76%',
            splitNumber: 10,
            axisLine: {
              lineStyle: {
                color: [
                  [
                    100,
                    new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                      {
                        offset: 0,
                        color: 'rgba(145,207,255,0)',
                      },
                      {
                        offset: 0.5,
                        color: 'rgba(74, 156, 156, 0.7)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(86, 212, 212,1)',
                      },
                    ]),
                  ],
                  [1, 'rgba(28,128,245,.0)'],
                ],
                width: 3,
              },
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            itemStyle: {
              show: false,
            },
            detail: {
              show: false,
            },
            title: {
              //标题
              show: false,
            },
            data: [
              {
                name: 'title',
                value: value,
              },
            ],
            pointer: {
              show: false,
            },
            animationDuration: 4000,
          },
          {
            name: '内部圈',
            type: 'gauge',
            z: 2,
            min: 0,
            max: 10,
            splitNumber: 10,
            radius: '70%',
            axisLine: {
              show: true,
              lineStyle: {
                width: 13,
                color: nqColor,
              },
            },
            tooltip: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            detail: {
              show: false,
            },
            title: {
              //标题
              show: false,
            },
            data: [
              {
                name: 'title',
                value: value / 10,
              },
            ],
            itemStyle: {
              show: false,
              normal: {
                color: 'rgba(86, 212, 212,1)',
              },
            },
            pointer: {
              show: true,
              length: '80%',
              radius: '20%',
              width: 6, //指针粗细
            },
            animationDuration: 1000,
          },
          {
            name: '内部刻度',
            type: 'gauge',
            radius: '55%',
            min: 0, //最小刻度
            max: 10, //最大刻度
            splitNumber: 10, //刻度数量
            startAngle: 225,
            endAngle: -45,
            axisLine: {
              show: false,
              lineStyle: {
                width: 5,
                color: 'rgba(86, 212, 212,1)',
              },
            }, //刻度标签。
            axisLabel: {
              show: false,
              fontSize: 12,
            },
            axisTick: {
              show: true,
              splitNumber: 7,
              lineStyle: {
                color: 'rgba(86, 212, 212,1)', //用颜色渐变函数不起作用
                width: 1,
              },
              length: 4,
            }, //刻度样式
            splitLine: {
              show: true,
              length: 8,
              lineStyle: {
                color: '#1C3164', //用颜色渐变函数不起作用
              },
            }, //分隔线样式
            detail: {
              show: false,
            },
            pointer: {
              show: false,
            },
          },
          {
            //指针上的圆
            type: 'pie',
            tooltip: {
              show: false,
            },
            hoverAnimation: false,
            legendHoverLink: false,
            radius: ['0%', '20%'],
            center: ['50%', '50%'],
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: value / 10,
                itemStyle: {
                  normal: {
                    color: 'rgb(14,31,73)',
                  },
                },
              },
            ],
          },
        ],
      };
    }
  },
  watch: {
    mapDataArr: {
      immediate: true,
      handler() {
        const dataX = this.mapDataArr.map((d) => {
          return { name: d.regionName, value: randomByLevel({ level: this.mapLevel }) };
        });
        this.init();
        this.option = mutBarChart(
          {
            leArr: ['培训次数', '就业总数'],
            xData: dataX.map((d) => d.name),
            yFirst: dataX.map((d) => Math.floor(d.value * 5)),
            ySecond: dataX.map((d) => Math.floor(d.value * 8)),
          },
          1000
        );
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.bordered {
  height: 720px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // background: rgba(5, 41, 92, .6);
  // border: 2px solid rgba(17, 94, 205, .2);
}
.item {
  height: 50px;
  width: 420px;
  background-color: #0c2857;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  font-size: 14px;
  font-weight: bold;

  .column2 {
    font-size: 26px;
    font-weight: 500;
    color: #00ffff;
    line-height: 35px;
  }
}

.echarts {
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: space-around;

  .dashboard {
    width: 48%;
    height: 100%;
    position: relative;

    img {
      position: absolute;
      width: 110px;
      top: 23%;
      left: 25%;
      z-index: 0;
    }

    .circle {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background-color: rgba(86, 212, 212, 0.7);
      position: absolute;
      top: 32%;
      left: 34%;
      display: flex;
      justify-content: center;
      align-items: center;

      .txt {
        font-size: 28px;
        color: #164675;
      }
    }

    .suffix {
      color: #164675;
      font-size: 14px;
    }

    .title {
      width: 113px;
      height: 32px;
      background: #041935;
      border: 1px solid #00ddec;
      border-radius: 16px;
      position: absolute;
      top: 89%;
      left: 25%;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 10px 5px rgba(86, 212, 212, 0.7) inset;
    }
  }
}</style>
