<template>
	<div class="bordered h100">
	  <Title>
		中医机构
	  </Title>
	  <TotalAnalysis style="marginBottom: 0" :value="valueByLevel"></TotalAnalysis>
	  <div class="cardContainer">
		<div class="card" :key="i.name" v-for="i in cardByLevel">
		  <countTo :startVal="0" :endVal="i.value" :duration="1000" class="value"></countTo>
		  <div class="name">{{ i.name }}</div>
		</div>
	  </div>
	</div>
  </template>
  <script>
  import Title from '../components/Title';
  import { numberAppendSeparator } from '@/utils'
  import countTo from 'vue-count-to';
  import { mapState } from 'vuex';
  import TotalAnalysis from '../components/TotalAnalysis.vue'
  export default {
	components: { Title, countTo ,TotalAnalysis},
	data() {
	  return {
		// num: numberAppendSeparator(183251).split(''),
		card: [{name: '中医康养设备数', value: 65873}, {name: '诊疗方案数', value: 21036}, {name: '合作厂家数', value: 41387}],
		analysisNum: 40758,
	  }
	},
	computed:{
	  ...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
	  num(){
	  return  numberAppendSeparator(Math.round(183251/(this.mapLevel+1))).split('')
	  },
	  cardByLevel(){
		return this.card.map(item=>{
		  return{
			...item,
			value:item.value/(this.mapLevel+1)
		  }
		})
	  },
	  valueByLevel(){
      return this.analysisNum / (this.mapLevel + 1)
    }
	}
  }
  </script>
  <style lang="scss" scoped>
	
	.bordered{
		background: rgba(5, 41, 92, .6);
		border: 2px solid rgba(17, 94, 205, .2);
	}
  .h100{
  }
  .title {
	width: 100%;
	display: flex;
	justify-content: center;
	font-size: 14px;
  }
  
  .numContainer {
	display: flex;
	width: 100%;
	justify-content: center;
	gap: 4px;
  
	.num {
	  color: #F9F871;
	  font-size: 30px;
	  display: flex;
	  font-style: italic;
	  justify-content: center;
	  align-items: center;
	  background: url('../assets/medi_bg.jpg');
	  background-size: 100% 100%;
	  width: 36px;
	  height: 43px;
	}
  }
  .cardContainer {
	display: flex;
	justify-content: space-around;
	width: 100%;
	gap: 16px;
	padding: 0 16px;
	.card {
	  flex: 1;
	  height: 130px;
	  background: url('../assets/medi_bg2.jpg');
	  background-size: 100% 100%;
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  align-items: center;
	  .name {
		font-size: 12px;
	  }
	  .value {
		color: #00FFFF;
		font-size: 26px;
	  }
	}
  }
  </style>