<template>
	<div class="bordered">
		<Title>中医药服务</Title>
		<div>
			<div class="statContainer">
				<div class="title">
				<div class="bg" :style="{width: usagerate + '%'}"></div>
				<span class="span">康养设备使用率</span>
				<div class="column2">
				<countTo :startVal="0" :endVal="usagerate" :duration="CountAnimationDuration"></countTo>%
				</div>
				</div>
				<div v-for="item in serveData" class="item">
					<span>{{ item.label }}</span>
					<countTo class="column2" :startVal="0" :endVal="item.value" :duration="CountAnimationDuration">
					</countTo>
				</div>
			</div>
			<div class="barContainer">
				<BaseEchart :option="option" style="width: 100%; height: 274px"></BaseEchart>
			</div>
		</div>
	</div>
</template>
<script>
import ProgressBar from '../components/ProgressBar.vue';
import BaseEchart from '@/components/BaseEchart';
import MockChinaData from '@/utils/MockChinaData';
import { random } from '@/utils';
import Title from '../components/Title';
import { mutBarChart } from '../common';
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';
import { mapState } from 'vuex';
import { randomByLevel } from '@/views/homePage/common';
export default {
	components: { Title, BaseEchart, countTo, ProgressBar },
	data() {
		return {
			CountAnimationDuration,
			option: undefined,
			dataType: '1',
			statData: [
				{ label: '慢性病病人规范管理率', value: 58 },
				{ label: '高血压患者管理率', value: 62 },
				{ label: '高血压患者规范管理率', value: 31 },
				{ label: '管理人群血压控制率', value: 58 },
				{ label: '糖尿病患者管理率', value: 72 },
				{ label: '糖尿病患规范健康管理率', value: 58 },
				{ label: '管理人群血糖控制率', value: 58 },
			],
			barData: [],
			tableData: [],
			serveDataf: [
				{
					label: '服务群众数', value: 40758
				},
				{
					label: '康养设备投放数', value: 11248
				},
			],
			usageratef: 80
		};
	},
	mounted() {
		this.init();
	},
	computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
		usagerate(){
			return this.usageratef-(random(0,10)*(this.mapLevel+1))
		},
		serveData(){
			return this.serveDataf.map(item=>{
				return{
					...item,
					value:item.value/(this.mapLevel+1)
				}
			})
		}
	},
	watch: {
		mapDataArr: {
			handler() {
				this.barData = this.mapDataArr.map((d) => {
					return { name: d.regionName, value: randomByLevel({ level: this.mapLevel }) };
				})
				this.option = mutBarChart({
					leArr:['服务群众','康养设备投放'],
					xData: this.barData.map(d => d.name),
					yFirst: this.barData.map((d) => d.value * 4),
					ySecond: this.barData.map((d) => d.value * 9),
				})
				this.tableData = this.mapDataArr.map((k) => {
					return { data1: k.regionName, data2: random(0, 100), data3: random(0, 100), data4: random(0, 100), data5: random(0, 100) };
				});
			}
		}
	},
	methods: {
		onRadioChange(e) {
			this.dataType = e;
		},
		init() {
			const dataX = Object.keys(MockChinaData).map((k) => {
				return { name: k, value: random(0, 100) };
			});
			this.tableData = Object.keys(MockChinaData).map((k) => {
				return { data1: k, data2: random(0, 100), data3: random(0, 100), data4: random(0, 100), data5: random(0, 100) };
			});
			this.barData = Object.keys(MockChinaData).map((k) => {
				return { name: k, value: random(0, 100) };
			});
			this.option = mutBarChart({
				leArr:['服务群众','康养设备投放'],
				xData: this.barData.map(d => d.name),
				yFirst: this.barData.map((d) => d.value * 4),
				ySecond: this.barData.map((d) => d.value * 9),
			})
		},
	},
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
/deep/ .el-table {
	background-color: transparent !important;
}

/deep/ .el-table,
.el-table__expanded-cell {
	background-color: transparent; //这是设置透明背景色
}

/deep/ .el-table__header-wrapper {
	tr {
		background-color: transparent;
	}

	.odd {
		font-size: 14px;
	}

	.even {
		font-size: 14px;
	}

	.el-table__cell {
		color: white;
		background-color: #153b6b;
		border-bottom-color: #081735;
	}
}

/deep/ .el-table__body-wrapper {
	.el-table__row {
		background-color: #0d2e5a !important;
	}

	.el-table__row--striped {
		.el-table__cell {
			background-color: #153b6b !important;
		}
	}
}

/deep/ .hover-row>td {
	background-color: initial !important;
}

/deep/ .first {
	div {
		color: rgba(255, 255, 255, 0.8);
	}
}

/deep/ .odd {
	color: #1fbdff;
	font-size: 18px;
}

/deep/ .even {
	color: #00f0ff;
	font-size: 18px;
}

.statContainer {
	display: flex;
	flex-wrap: wrap;
	// background-color: #112c58;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	.bg{
		background: linear-gradient(90deg, #40A4E3 0%, #4AAEFF 100%);
		height: 50px;
		position: absolute;
			left: 0;
	}
	.title {
		height: 50px;
		width: 420px;
		background-color: #0c2857;
		padding-right: 20px;
		position: relative;
		display: flex;
    align-items: center;
		.span{
			position: absolute;
			left: 20px;
		}
		.column2 {
			width: 80px;
			height: 35px;
			font-size: 26px;
			font-weight: 500;
			color: #00FFFF;
			line-height: 35px;
			z-index: 10;
      position: absolute;
      right: 8px;
		}
	}

	.item {
		height: 50px;
		width: 420px;
		background-color: #0c2857;
		margin-top: 10px;
		display: flex;
		justify-content: space-between;
		padding-left: 20px;
		padding-right: 20px;
		align-items: center;
		font-size: 14px;
		font-weight: bold;

		.column2 {
			font-size: 26px;
			font-weight: 500;
			color: #00FFFF;
			line-height: 35px;
		}
	}
}

.barContainer {
	display: flex;
	flex-direction: column;
	padding: 12px;
	overflow-y: auto;
	height: 284px;

	.bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;
		margin-bottom: 24px;

		.name {
			width: 42px;
			flex-shrink: 0;
			text-align: right;
		}

		.value {
			width: 30px;
			flex-shrink: 0;
			text-align: right;
		}

		.p {
			flex: 1;
			width: unset !important;
		}
	}
}

.stat {
	margin: 16px 12px;
	padding: 0 16px;
	height: 53px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #0e2e62;

	.name {
		font-size: 14px;
		color: white;
	}

	.value {
		color: $main-text;
		font-size: 26px;
	}
}

.boardContainer {
	display: flex;
	gap: 12px;
	padding: 0 12px;

	.bar {
		flex: 1;

		.label {
			margin-bottom: 4px;
		}

		.suffix {
			margin-left: 6px;
		}
	}
}</style>
